import { useStaticQuery, graphql } from "gatsby"
export const useMenuQuery = () => {
  const data = useStaticQuery(
    graphql`
    query MyQuery {
      site {
        siteMetadata {
          title
        }
      }
      wpMenu(name: {eq: "Hoofdmenu"}) {
        id
        menuItems {
          nodes {
            label
            url
            uri
            id
            path
            childItems {
              nodes {
                label
                url
                uri
                id
                description
                path
                parentId
                childItems {
                  nodes {
                    id
                    url
                    uri
                    label
                    parentId
                    path
                  }
                }
                parentId
              }
            }
            parentId
          }
        }
      }
      allWpVestiging {
        edges {
          node {
            title
            uri
            id
            vestigingen {
              contactpersoon
              showVestiging
              afbeeldingContactpersoon {
                id
                mediaItemUrl
              }
            }
          }
        }
      }
    }
    
    `
  )
  return data;
}