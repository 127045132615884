import React from "react";
import './Container.css'


const Container = (props) => {
    const classes = props.styles
    const style = props.styling
    

    return(
            <div className={`container max-w-screen-xl mx-auto ${classes != undefined && classes || ''}`}>
                {props.children}
            </div>
    )
}

export default Container;