import React, { useState } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Notification = (props) => {
    const isVis = props.vis

    return(
        <motion.div initial={{opacity: 0, translateY: -20}} animate={{ opacity: 1, translateY: 0 }} transition={{ duration: .3, delay: 0 }} className={`bg-transparent w-screen h-screen flex-row items-start pt-48 justify-center ${isVis ? `flex absolute z-50` : `hidden`}`}>
            <div className="w-8/12 h-auto bg-white">
                <div className="w-full flex flex-row items-center justify-between bg-blue text-light px-8 py-5">
                    <h2 className="m-0 mr-4 text-2xl">{props.title}</h2>
                    <span><FontAwesomeIcon onClick={props.closeForm} icon={faTimes} className="cursor-pointer"></FontAwesomeIcon></span>
                </div>
                <div className="w-full px-8 py-5">
                    <p className="m-0">{props.body}</p>
                </div>
            </div>
        </motion.div>
    )
}

export default Notification;