import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { AnimatePresence, motion } from "framer-motion"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import VestigingContext from "../../store/vestiging-context"
import "./layout.css"
import Notification from "../Notifications/Notifications"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [vestiging, setVestiging] = useState()
  const [notificationVis, setNotificationVis] = useState(false)
  const [notificationTitle, setNotificationTitle] = useState(`Test`);
  const [notificationBody, setNotificationBody] = useState(`Body`);

  const notificationVisibility = () => {
    setNotificationVis(false)
  }

  const vestigingHandler = (e) => {
      if(e !== `Selecteer een vestiging`){
        setVestiging(e)
        sessionStorage.setItem('Vestiging', e )
        // setNotificationVis(true)
        // setNotificationTitle(`Vestiging ${e} geselecteerd.`)
        // setNotificationBody(`Je hebt de vestiging ${e} geselecteerd, je krijgt nu alle informatie te zien die met de vestiging ${e} te maken heeft. Om terug te gaan naar de algemene website klik je op het kruisje naast de geselecteerde vestiging.`)
      }else{
        setVestiging(undefined)
      }
  }

  useEffect(() => (
    sessionStorage.getItem('Vestiging') !== null ? vestigingHandler(sessionStorage.getItem('Vestiging')) : null
  ), [])
  




  return (
    <VestigingContext.Provider value={{
      vestiging: vestiging,
      change: vestigingHandler
    }}>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} >
            <Notification vis={notificationVis} title={notificationTitle} body={notificationBody} closeForm={notificationVisibility}></Notification>
            <Helmet>
            <script>
                {
                    `(function(h,o,t,j,a,r){
                      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                      h._hjSettings={hjid:3407167,hjsv:6};
                      a=o.getElementsByTagName('head')[0];
                      r=o.createElement('script');r.async=1;
                      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                      a.appendChild(r);
                  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
                }
            </script> 
            <script src="https://onlinewoningbrochure.nl/api/js/287"></script>
            </Helmet>          
              <main>
                {children}
              </main>
            </motion.div>
        <Footer />
    </VestigingContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
