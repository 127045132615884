module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Groenhout Makelaars","short_name":"Groenhout Makelaars","start_url":"/","background_color":"#cdcdcd","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6f26407aac93aa90c7a631a9e2d4aee3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://groenhoutmakelaars.hq-beheer.nl/graphql","production":{"hardCacheMediaFiles":true,"allow404Images":true,"allow401Images":true},"schema":{"typePrefix":"Wp","perPage":20,"requestConcurrency":100,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"timeout":30000},"type":{"MediaItem":{"createFileNodes":false,"localFile":{"requestConcurrency":200,"maxFileSizeBytes":1,"excludeByMimeTypes":[]},"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"Post":{"limit":10000},"Woning":{"limit":10000},"Villa":{"limit":10000},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"debug":{"graphql":{"showQueryOnError":true,"showQueryVarsOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-P5VJP77"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
