import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import VestigingContext from "../../../store/vestiging-context";
import Container from "../../Container/Container";
import placeholder from "../../../images/woning.jpg"
import { motion } from "framer-motion";

const MegaMenu = (props) => {

    const data = props.menu
    const Arr = data.menuItems.nodes
    const vestigingen = props.vestigingen
    const selected = props.selected
    const isVisible = props.isVisible

    const selectedVestiging = useContext(VestigingContext);

    // const onSelectHandler = (e) => {
    //     selectedVestiging.change(e.target.innerHTML)
    // }

    const menuItems = Arr.map((menuItem) => (
        <>
                {
                menuItem.childItems.nodes.map((menu) => (
                
                (menu.childItems.nodes.length > 0 && menu.parentId === selected) ?
                    <div key={menu.id} className="px-8">
                        <Link to={menu.path} className="m-0 font-medium text-base">{menu.label}</Link>
                        <ul className="m-0 mt-2 text-gray text-sm">
                            {menu.childItems.nodes.map((subItem) => (
                                <Link to={subItem.path} className="text-gray"><li key={subItem.id}>{subItem.label}</li></Link>
                            ))} 
                        </ul>
                        {/* <Link to={menu.url}>Lees meer...</Link> */}
                    </div>

                :  (menu.parentId === selected && menu.childItems.nodes.length === 0) ?
                    <div key={menu.id} className="md:w-full lg:w-8/12 px-8">
                        <Link to={menu.path}><p className="m-0 font-medium text-base">{menu.label}</p></Link>
                        <p className="m-0 my-2 text-gray text-sm">{menu.description}</p>
                        {/* <Link to={menu.url}>Lees meer...</Link> */}
                    </div>
                
                : null
                
                ))}
        </>
    ))

    // const vestigingSort = vestigingen.edges.sort((a, b) => a.node.title.localeCompare(b.node.title) )
    // const vestigingenData = vestigingSort.map((vestiging) => (
    //    <li key={vestiging.node.id} onClick={onSelectHandler} className={`cursor-pointer hover:text-blue ${selectedVestiging.vestiging !== undefined && selectedVestiging.vestiging === vestiging.node.title ? `text-blue` : `text-gray`}`}>{vestiging.node.title}</li>
    // ))

    return(
        <Container styles={"w-full lg:flex hidden h-auto max-w-none bg-white mega__menu transition-spacing duration-300 z-50" + (isVisible ? 'absolute top-22 py-5 opacity-100 ' : 'h-0 py-0 opacity-0') } >
            {/* <Container> */}
                <div className="flex flex-row justify-end w-full py-3">
                    <div className="w-full lg:w-8/12 lg:ml-auto flex flex-row justify-end">
                        {menuItems}
                    </div>
                </div>
            {/* </Container> */}
        </Container>
    )
}

export default MegaMenu