/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/global.css';

import React from "react"
import Layout from "./src/components/Layout/Layout"

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps
}) => {

  
  // Check if navigating to '/aanbod/spanje'
  if (location.pathname === '/aanbod/spanje') {
    console.log('false')
    return false;
  }

  // If the function doesn't return anything, Gatsby will assume true and proceed with the default behavior.
}

export const onRouteUpdate = ({ location }) => {
  if (location.pathname === '/aanbod/spanje' && typeof window !== 'undefined') {
      const savedScrollPosition = sessionStorage.getItem('scrollPosition');
      if (savedScrollPosition) {
        requestAnimationFrame(() => {
          window.scrollTo(0, parseInt(savedScrollPosition, 10));
        });
          sessionStorage.removeItem('scrollPosition'); // Clear after using
      }
  }
};