import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useContext } from "react"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { useFooterQuery } from "../../hooks/useFooterQuery"
import VestigingContext from "../../store/vestiging-context"
import Container from "../Container/Container"
import FooterMenu from "./FooterMenu/FooterMenu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"
import funda from "../../images/funda-icon.png"

const Footer = () => {
  const data = useFooterQuery()
  const ctx = useContext(VestigingContext)
  const selected = ctx.vestiging
  const sellerData = data.allWpVerkopendeMakelaar
  const vestigingHead = data.allWpVestiging.nodes.map(e =>
    selected == e.title ? e.vestigingen.contactpersoon : null
  )

  const voorwaarden = useStaticQuery(graphql`
    query VoorwaardenQuery {
      wpMenu(slug: { eq: "voorwaarden" }) {
        menuItems {
          nodes {
            id
            url
            path
            label
          }
        }
      }
    }
  `)

  const voowaardenMenu = voorwaarden.wpMenu.menuItems.nodes.map(v => {
    return (
      <Link to={v.url} target={"_blank"} className="mr-4 text-white">
        {v.label}
      </Link>
    )
  })

  const lid =
    data.allWp.edges[0].node.acfOptionsAlgemeneInstellingen.footer.lid.map(
      (l, i) => (
        <div className={`${i > 0 ? "mt-3" : ""} mr-3 lg:mr-0`}>
          <img
            src={l.lidAfbeelding.mediaItemUrl}
            className="object-contain h-12 m-0"
          ></img>
        </div>
      )
    )

  const awards =
    data.allWp.edges[0].node.acfOptionsAlgemeneInstellingen.footer.titelEnAwards.map(
      t => <p className="m-0">{t.titelAward}</p>
    )

  const getImg = data.allWpVestiging.nodes.map(e =>
    selected === e.title
      ? getImage(e.vestigingen.afbeeldingContactpersoon.localFile)
      : null
  )
  const imgObj = getImg.filter(e => {
    return e !== null
  })

  let seller = null
  seller = sellerData.nodes.filter(sell => {
    if (sell.verkopendeMakelaars.vestiging === selected) {
      return true
    } else {
      return false
    }
  })

  const fundaLink =
    ctx.vestiging === "Assen"
      ? `https://www.funda.nl/makelaars/4245-groenhout-makelaars-assen/?plaats=assen`
      : ctx.vestiging === `Beilen`
      ? `https://www.funda.nl/makelaars/4295-groenhout-makelaars-midden-drenthe/?plaats=beilen`
      : ctx.vestiging === `Hoogeveen`
      ? `https://www.funda.nl/makelaars/4280-groenhout-makelaars-hoogeveen/?plaats=hoogeveen`
      : `https://www.funda.nl/makelaars/4245-groenhout-makelaars-assen/?plaats=assen`
  const insta =
    ctx.vestiging === "Assen"
      ? `https://www.instagram.com/groenhoutmakelaars/`
      : ctx.vestiging === `Beilen`
      ? `https://www.instagram.com/groenhoutmakelaars/`
      : ctx.vestiging === `Hoogeveen`
      ? `https://www.instagram.com/groenhoutmakelaars/`
      : `https://www.instagram.com/groenhoutmakelaars/`
  const fb =
    ctx.vestiging === "Assen"
      ? `https://www.facebook.com/groenhoutmakelaars/`
      : ctx.vestiging === `Beilen`
      ? `https://www.facebook.com/groenhoutmakelaars/`
      : ctx.vestiging === `Hoogeveen`
      ? `https://www.facebook.com/groenhoutmakelaars/`
      : `https://www.facebook.com/groenhoutmakelaars/`

  const linkedin =
    ctx.vestiging === `Assen`
    ? `https://www.linkedin.com/company/groenhout-makelaars`
    : ctx.vestiging == `Beilen`
    ? `https://www.linkedin.com/company/groenhout-makelaars`
    : ctx.vestiging === `Hoogeveen`
    ? `https://www.linkedin.com/company/groenhout-makelaars`
    : `https://www.linkedin.com/company/groenhout-makelaars`

  return (
    <footer className="w-full py-8 px-8 lg:px-0 lg:pt-16 lg:pb-8  bg-blue">
      <Container styles="flex flex-col lg:flex-row justify-between sm:px-8 lg:px-0 pb-8 lg:text-left">
        <FooterMenu data={data} />
        <div className="w-full lg:w-1/4 text-light flex flex-col">
          <div>
            <h4>Wij zijn lid van</h4>
            <div className="flex flex-row items-center lg:flex-col">{lid}</div>
          </div>
        </div>
      </Container>
      <Container>
        <hr className="mb-0" />
      </Container>
      <Container styles="pt-8 sm:px-8 lg:px-0 text-light text-sm lg:text-left flex flex-col lg:flex-row justify-between">
        <div className="flex flex-col justify-between">
          <span>{voowaardenMenu}</span>
          <span className="opacity-40">
            © {new Date().getFullYear()} Groenhout Makelaars | Website door{" "}
            <a
              className="text-white"
              href="https://hq-online.nl/"
              target={"_blank"}
            >
              HQ Online
            </a>
          </span>
        </div>
        <div className="flex flex-row justify-between lg:flex-col w-full lg:w-1/5 mt-4 lg:mt-0">
          <h4 className="mb-0 lg:mb-4 text-light">Blijf op de hoogte</h4>
          <div className="flex flex-row">
            <a href={fb} target="_blank">
              <FontAwesomeIcon
                icon={faFacebookF}
                className="text-white mr-4"
                size="lg"
              ></FontAwesomeIcon>
            </a>
            <a href={insta} target="_blank">
              <FontAwesomeIcon
                icon={faInstagram}
                size="lg"
                className="text-white mr-4"
              ></FontAwesomeIcon>
            </a>
            <a href={linkedin} target="_blank">
              <FontAwesomeIcon
                icon={faLinkedin}
                size="lg"
                className="text-white"
              ></FontAwesomeIcon>
            </a>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
