import React, { useEffect, useState, useContext } from "react"
import Logo from "../../images/logo.png"
import Logo2 from "../../images/logo2.png"
import NewLogo from "../../images/logo.svg"
import { Link } from "gatsby"
import { useMenuQuery } from "../../hooks/useMenuQuery"
import Navigation from "../Navigation/Navigation"
import "./header.css"
import MegaMenu from "./MegaMenu/MegaMenu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import VestigingContext from "../../store/vestiging-context"
import Select from "../Select/Select"
import VestigingSelect from "./VestigingSelect/VestigingSelect"

const Header = props => {
  const { site, wpMenu, allWpVestiging } = useMenuQuery()
  const [selectedMenu, setSelectedMenu] = useState("")
  const [isVisible, setIsVisible] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [width, setWidth] = useState()

  const selectedVestiging = useContext(VestigingContext)

  const handleMouseEnter = data => {
    setSelectedMenu(data)
    setIsVisible(true)
  }

  const handleMouseLeave = () => {
    setSelectedMenu(``)
    setIsVisible(false)
  }

  const [offset, setOffset] = useState(0)

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset)
    }
    setWidth(window.innerWidth)
  }, [])

  const clickHandler = () => {
    setOpenMenu(v => !v)
  }

  return (
    <header
      className={`z-50 fixed w-full top-0`}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`full flex flex-col lg:flex-row justify-between bg-transparent transition-all lg:bg-blue-light  ${
          offset > 50 ? " lg:bg-blue-light" : " "
        }`}
      >
        <div
          className={`menu--before w-full lg:w-4/12 2xl:w-4/12 flex flex-col lg:flex-row items-start md:items-center  lg:p-5 lg:pr-0 xl:px-10 lg:bg-transparent bg-blue bg-opacity-80 transition-all `}
        >
          <div
            className={`w-full lg:w-auto flex flex-row justify-between items-center lg:bg-transparent z-50 lg:px-0 transition-all duration-300 lg:py-0 px-5 ${
              offset > 50 ? "py-2" : "py-7 md:py-2"
            } ${openMenu ? "bg-blue bg-opacity-80" : ""}`}
          >
            <Link
              to="/"
              onClick={() => setOpenMenu(false)}
              className={`cursor-pointer flex justify-start md:justify-start transition-all duration-300 z-50 ${
                offset > 50 ? "w-36 md:w-auto" : " w-auto"
              }`}
            >
              <img
                className="logo m-0 cursor-pointer transition-all duration-300"
                src={NewLogo}
                alt={site.siteMetadata.title}
              />
            </Link>
            <div
              id={`nav-icon1`}
              className={`${openMenu ? "open" : ""} lg:hidden z-50`}
              onClick={clickHandler}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <div
            className={` ml-0 md:mt-0 2xl:ml-4 text-light w-full lg:w-8/12 xl:w-10/12 flex flex-row lg:block lg:pb-0 px-5 ${
              offset > 50 ? "mt-0 pb-4" : " mt-0 pb-7 md:pb-4"
            }`}
          >
            {/* <Select placeholder="Onze vestigingen" data={allWpVestiging.edges} ></Select> */}
            {/* <VestigingSelect vestigingen={allWpVestiging} mouseEnter={handleMouseEnter} mouseLeave={handleMouseLeave} select={selectedVestiging} offset={offset} /> */}
            {/* <div className={`block cursor-pointer lg:hidden mr-5 flex items-center justify-end md:relative md:top-auto md:right-auto transition-all  ${offset > 50 ? `top-6 right-8 ` : `top-10 right-8 `} `}  onClick={clickHandler} ><FontAwesomeIcon icon={faBars} size="2x" className="cursor-pointer" /> </div> */}
          </div>
        </div>
        <div
          className={
            `w-full lg:w-8/12 2xl:w-10/12 h-screen lg:h-auto p-0 lg:pl-4 dark:bg-darkGray lg:flex lg:justify-end transition-all duration-300 lg:relative lg:-top-0 lg:opacity-100 lg:block lg:left-0 ${
              openMenu ? `fixed left-0` : `fixed -left-full`
            } ` + (offset > 50 ? "" : "")
          }
        >
          <Navigation
            data={wpMenu}
            width={width}
            visible={openMenu}
            mouseEnter={handleMouseEnter}
            mouseLeave={handleMouseLeave}
            closeMenu={clickHandler}
            offset={offset}
          ></Navigation>
        </div>
      </div>
      <MegaMenu
        menu={wpMenu}
        vestigingen={allWpVestiging}
        selected={selectedMenu}
        isVisible={isVisible}
        mouseEnter={handleMouseEnter}
      ></MegaMenu>
    </header>
  )
}
export default Header
