import React, { useState } from "react";

const Select = (props) => {
    const data = props.data
    const placeholder = props.placeholder
    const selectArr = data
    const selectContent = selectArr.map((selectItem) => (
        <option key={selectItem.node.id} value={selectItem.node.uri} className="text-blue">{selectItem.node.title}</option>
    ))
    const changeHandler = props.changeHandler

    return(
        <select className=" px-3 py-2 bg-transparent text-light" onChange={changeHandler} >
            {placeholder !== undefined && 
                <option>{placeholder}</option>
            }   
            {selectContent}
        </select>
    )
}

export default Select;