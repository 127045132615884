import { useStaticQuery, graphql } from "gatsby"
export const useFooterQuery = () => {
  const data = useStaticQuery(
    graphql`
    query FooterQuery {
        wpMenu(slug: {eq: "footer"}) {
          menuItems {
            nodes {
              id
              childItems {
                nodes {
                  id
                  url
                  path
                  label
                  childItems {
                    nodes {
                      id
                      url
                      path
                      label
                      parentId
                      path
                    }
                  }
                }
              }
              parentId
              url
              path
              label
            }
          }
        }
        allWpVestiging {
          nodes {
            title
            vestigingen {
              contactpersoon
              afbeeldingContactpersoon {
                mediaItemUrl
              }
            }
          }
        }
        allWpVerkopendeMakelaar {
          nodes {
            title
            verkopendeMakelaars {
              fieldGroupName
              foto {
                mediaItemUrl
              }
              emailadres
              telefoonnummer
              vestiging
              volgnummer
            }
          }
        }
        allWp {
          edges {
            node {
                acfOptionsAlgemeneInstellingen {
                  footer {
                    titelEnAwards {
                      fieldGroupName
                      titelAward
                    }
                    titelsAwardsTitel
                    lid {
                      lidAfbeelding {
                        mediaItemUrl
                    }
                  }
                }
              }
            }
          }
        }
      }      
    `
  )
  return data;
}

// allWpVestiging {
//   nodes {
//     title
//     id
//     uri
//     vestigingen {
//       contactpersoon
//       afbeeldingContactpersoon {
//         id
//         sourceUrl
//         localFile {
//           childImageSharp {
//             gatsbyImageData
//           }
//         }
//       }
//     }
//   }
// }