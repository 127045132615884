import { Link } from "gatsby";
import React from "react";

const FooterMenu = (props) => {

    const data = props.data.wpMenu.menuItems.nodes
    const menu = data.map((e) => {
        
        if(e.parentId == null){
            return(
                <div key={e.id} className="w-full lg:w-1/3 mt-5 lg:mt-0">
                    {e.url.startsWith('http://nolink') ?
                        <h4 className="text-light">{e.label}</h4>
                    :
                        <Link to={e.path}><h4 className="text-light transition-all duration-300">{e.label}</h4></Link>
                    }
                    <ul className="pr-4 mb-8 ml-0 lg:mb-0">
                        {e.childItems.nodes.map((child)=>{
                            if(child.url.startsWith('http://nolink')){
                                
                            } else{
                                return <Link to={child.path} key={child.id} target= {child.path.startsWith('https://') ? '_blank' : null} className="text-light font-normal text-sm transition-all duration-300 "><li className="mb-4"><span className="buttonHoverWhite">{child.label}</span></li></Link>
                            }    
                        })}
                    </ul>
                </div>
            )
        }
    })

    return(
        <>
            {menu}
        </>   
    )
}

export default FooterMenu;